import {
  faCircleXmark,
  faFileArrowDown,
  faFileArrowUp,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Timeline,
  Upload,
  Row, Col, Card, Typography
} from "antd";
import React, { lazy, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import loaderStore from "../../../stores/loaderStore";
import publicStore from "../../../stores/publicStore";
import studentStore from "../../../stores/studentStore";
import userStore from "../../../stores/userStore";
import { Constant } from "../../../utils/constant";
import { GetUserRole, toastError, toastSuccess } from "../../../utils/helper";
// import LearnIndex from "../learn";
// import CreateApplicationModal from "../../dashboard/student/CreateApplicationModal";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
dayjs.extend(customParseFormat);
const { Text, Title } = Typography;

const CreateApplicationModal = lazy(() =>
  import("../../dashboard/student/CreateApplicationModal")
);

export default function UserProfile() {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get("student");
  const { showLoader, hideLoader } = loaderStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [progressStatusId, setProgressStatusId] = useState(null);
  const [progressStatus, setProgressStatus] = useState(null);
  const [DOB, setDOB] = useState(null);
  const { applicationStatuses, fetchApplicationStatuses } = publicStore();
  const { profile, getUserProfile, updateMyProfile } = userStore();
  const {
    documents,
    getAllDocument,
    applications,
    getAllApplications,
    academicDocumentList,
    getAcademicDocumentNames,
    studentUploadDocument,
    updateApplications,
    removeUploadedDocument,
    deleteApplication,
  } = studentStore();

  useEffect(() => {
    // fetchUserProifile();
    getUserProfile(studentId ? studentId : null);
    getAllDocument(studentId ? studentId : null);
    getAllApplications(studentId ? studentId : null);
    getAcademicDocumentNames();
    fetchApplicationStatuses();
  }, []);

  const refreshApplication = () => {
    getAllApplications(studentId ? studentId : null);
  };

  const getProgressStatus = (index) => {
    return applications[index].progress_track_json.map((progress) => {
      return { children: progress.title };
    });
  };

  const isDissable = (id) => {
    return documents.find((doc) => doc.document_category === id) ? true : false;
  };

  const getFileName = (id) => {
    return documents.find((doc) => (doc.document_category === id ? doc : null));
  };

  const getFileId = (id) => {
    return documents.find((doc) => (doc.document_category === id ? doc : null));
  };

  const upload = async (file, id) => {
    showLoader();
    let formData = {
      file: file,
      document_category: id,
    };
    if (studentId) {
      formData.user = studentId;
    }

    studentUploadDocument(formData)
      .then((success) => {
        toastSuccess("Document uploaded successfully!");
        getAllDocument(studentId ? studentId : null);
      })
      .catch((error) => {
        toastError(error.response.data.file[0]);
      })
      .finally(() => hideLoader());
  };

  const submitProgressStatus = (applicationId) => {
    const data = {
      id: applicationId,
      value: {
        application_status: progressStatus,
        subtitle: "",
      },
    };
    updateApplications(data).then((success) => {
      refreshApplication();
    });
  };

  const applicationStatusesForSelect = () => {
    return applicationStatuses?.results?.map((course) => ({
      label: course.title,
      value: course.id,
    }));
  };

  const deleteFile = (id) => {
    showLoader();
    removeUploadedDocument(id)
      .then((success) => {
        toastSuccess("file has been deleted!");
        getAllDocument(studentId ? studentId : null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const removeApplication = (id) => {
    showLoader();
    deleteApplication(id)
      .then(() => {
        refreshApplication();
        toastSuccess("Application has been deleted!");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const onFinish = (value) => {
    console.log(DOB);
    value.dob = moment(DOB).format("YYYY-MM-DD");
    console.log(value); debugger;
    updateMyProfile(value).then((success) => {
      getUserProfile(studentId ? studentId : null);
      toastSuccess("Profile has been updated!");
      setIsEditModalOpen(false);
    }).catch((err) => {
      console.log(err);
    }).finally()
  };

  const onChange = (date, dateString) => {
    // console.log("date: ", date);
    setDOB(dateString);
  };

  return (
    <div>
      <div className="mb-5">
        <div className="flex items-center mb-5">
          <h1 className="mr-8">Profile</h1>
        </div>
        <Card style={{ padding: 10, background: '#ffffff', borderRadius: 10 }}>
          <button
            type="button"
            class="default-btn absolute right-0 -top-3"
            onClick={() => setIsEditModalOpen(true)}
          >
            <FontAwesomeIcon icon={faPenToSquare} className="mr-3" />
            Edit
          </button>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Text type="secondary">Full Name</Text>
              <Title level={5} className="font-semibold">{profile?.first_name + " " + profile?.last_name}</Title>
            </Col>
            <Col span={8}>
              <Text type="secondary">Email</Text>
              <Title level={5} className="font-semibold">{profile?.email}</Title>
            </Col>
            <Col span={8}>
              <Text type="secondary">DOB</Text>
              <Title level={5} className="font-semibold">{profile?.dob}</Title>
            </Col>
            <Col span={8}>
              <Text type="secondary">Address</Text>
              <Title level={5} className="font-semibold">{profile?.address}</Title>
            </Col>
            {profile?.role && (
              <Col span={8}>
                <Text type="secondary">Role</Text>
                <Title level={5} className="font-semibold">{profile?.role}</Title>
              </Col>
            )}
            <Col span={8}>
              <Text type="secondary">Phone Number</Text>
              <Title level={5} className="font-semibold">{profile?.phone}</Title>
            </Col>
            <Col span={8}>
              <Text type="secondary">Passport Number</Text>
              <Title level={5} className="font-semibold">{profile?.passport_number}</Title>
            </Col>
          </Row>
        </Card>
      </div>

      <Modal
        title="Edit"
        footer={false}
        maskClosable={false}
        open={isEditModalOpen}
        onOk={() => setIsEditModalOpen(false)}
        onCancel={() => setIsEditModalOpen(false)}
      >
        <Form
          form={form}
          layout={"vertical"}
          style={{
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          requiredMark={false}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError
          initialValues={profile}
        >
          <Form.Item
            label="first_name"
            className="required font-semibold mb-2"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="last_name"
            className="required font-semibold mb-2"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="phone"
            className="required font-semibold mb-2"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your phone!",
              },
              {
                pattern: /^\d{10}$/,
                message: "Phone number must be exactly 10 digits!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="DOB"
            className="required font-semibold"
            name={"dob"}
            valuePropName={"date"}
            rules={[
              {
                required: true,
                message: "Please input your Date of Birth!",
              },
            ]}
          >
            <DatePicker
              onChange={onChange}
              size="large"
              className="w-full"
              defaultValue={dayjs(profile.dob, "YYYY-MM-DD")}
            />
          </Form.Item>

          <Form.Item
            label="Passport Number"
            className="font-semibold mb-2"
            name="passport_number"
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Address"
            className="font-semibold mb-2"
            name="address"
          >
            <Input size="large" />
          </Form.Item>

          <div className="text-end mb-0">
            <Button type="primary" htmlType="submit" className="w-full">
              Update
            </Button>
          </div>
        </Form>
      </Modal>

      <div className="bg-white rounded-xl p-5 mb-5">
        <div className="lg:flex justify-between items-center mb-5">
          <h1>{studentId ? "Applications" : "My Applications"}</h1>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsModalOpen(true)}
          >
            Create New Application
          </Button>
        </div>
        {applications.map((application, index) => {
          return (
            <div className="grid lg:grid-cols-2 gap-5 mb-5">
              <div className="relative">
                <Popconfirm
                  title="Delete the Application"
                  description="Are you sure to delete this Application?"
                  onConfirm={() => removeApplication(application.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    shape="circle"
                    danger
                    icon={<FontAwesomeIcon icon={faTrashCan} />}
                    className="application-delete-btn"
                  />
                </Popconfirm>
                <table className="cm-table">
                  <tr>
                    <td>Country</td>
                    <td className="font-semibold">
                      {
                        application.course_details?.institute?.country_detail
                          .name
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Institute Name</td>
                    <td className="font-semibold">
                      {application.course_details?.institute?.title}
                    </td>
                  </tr>
                  <tr>
                    <td>Institute Address</td>
                    <td className="font-semibold">
                      {application.course_details?.institute?.address}
                    </td>
                  </tr>
                  <tr>
                    <td>Course Name</td>
                    <td className="font-semibold">
                      {application.course_details.title}
                    </td>
                  </tr>
                  <tr>
                    <td>Course Requirement</td>
                    <td className="font-semibold">
                      {application.course_details.academic_requirements}
                    </td>
                  </tr>
                  <tr>
                    <td>Duration</td>
                    <td className="font-semibold">
                      {application.course_details.duration} Year's
                    </td>
                  </tr>
                  <tr>
                    <td>Fees</td>
                    <td className="font-semibold">
                      {
                        application.course_details?.institute?.country_detail?.currency?.code +
                        " " +
                        application.course_details?.fee_international
                      }
                    </td>
                  </tr>
                </table>
              </div>

              <div>
                <h2>Progress</h2>
                {GetUserRole() !== Constant.student && (
                  <p className="mb-5">
                    Update Progress status{" "}
                    <span
                      className="underline font-semibold cursor-pointer"
                      onClick={() => setProgressStatusId(application.course)}
                    >
                      here.
                    </span>
                  </p>
                )}

                {progressStatusId === application.course && (
                  <div className="mb-5 flex">
                    {/* <Input
                      placeholder="Progress status"
                      onChange={(e) => setProgressStatus(e.target.value)}
                    /> */}
                    <Select
                      className="w-full"
                      showSearch
                      placeholder="Select progress status"
                      optionFilterProp="children"
                      options={applicationStatusesForSelect()}
                      onChange={(id) => setProgressStatus(id)}
                    />
                    <Button
                      type="primary"
                      className="ml-3"
                      onClick={() => submitProgressStatus(application.id)}
                    >
                      Submit
                    </Button>
                  </div>
                )}
                <Timeline items={getProgressStatus(index)} />
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-white rounded-xl p-5 mb-5">
        <h1 className="mb-3">{studentId ? "Documents" : "My Documents"}</h1>
        <div className="cm-table-responsive">
          <table className="cm-table">
            {academicDocumentList.map((doc) => {
              return (
                <tr>
                  <td>{doc.title}</td>
                  <td className="text-nowrap">
                    <div className="flex items-center">
                      <Upload
                        name="file"
                        beforeUpload={(data) => upload(data, doc.id)}
                        showUploadList={false}
                      >
                        <Button
                          icon={<FontAwesomeIcon icon={faFileArrowUp} />}
                          disabled={isDissable(doc.id)}
                          className="mr-5"
                        >
                          Click to upload document
                        </Button>
                      </Upload>
                      {getFileName(doc.id) && (
                        <>
                          <Link to={getFileName(doc.id)?.file}>
                            <FontAwesomeIcon
                              icon={faFileArrowDown}
                              className="mr-2"
                            />
                            Download File
                          </Link>
                          <Button
                            icon={<FontAwesomeIcon icon={faCircleXmark} />}
                            size="small"
                            className="mx-5"
                            danger
                            shape="circle"
                            type="primary"
                            onClick={() => deleteFile(getFileId(doc.id)?.id)}
                          ></Button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      {/* <LearnIndex /> */}

      {/* {GetUserRole() === Constant.agent && ( */}
      <CreateApplicationModal
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
        studentId={studentId}
        refreshApplication={refreshApplication}
      />
      {/* )} */}
    </div>
  );
}
