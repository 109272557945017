import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Popconfirm, Table, Tag, Tooltip, Row, Col, Card, Statistic, Select, Form } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BasicPagination from "../../../components/BasicPagination";
import adminStore from "../../../stores/adminStore";
import { Constant } from "../../../utils/constant";
import { toastSuccess } from "../../../utils/helper";
import PostModal from "../../../components/PostModal";

const { Option } = Select;

const type = Constant.degrees;

export default function DegreesList() {
  const { degrees, fetchDegrees, deleteModalStore } = adminStore();

  const [formData, setformData] = useState(null);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [loading, setloading] = useState(true);
  const [limit, setlimit] = useState(24);
  const [offset, setoffset] = useState(0);
  const [filters, setFilters] = useState({
    name__icontains: "",
    is_active: "",
  });
  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  useEffect(() => {
    initialApiCall();
  }, [limit, offset]);

  const initialApiCall = () => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);
    params.append("order", "-created_at");

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.set(key, filters[key]);
      }
    });

    fetchDegrees(params.toString()).finally(() => {
      setloading(false);
    });
  };

  const confirm = (id) => {
    deleteModalStore(id, type).then(() => {
      toastSuccess("Deleted successfully!");
      initialApiCall();
    });
  };

  const edit = (formData) => {
    setMode("edit");
    setformData(formData);
    setisModalOpen(true);
  };

  const applyFilters = () => {
    initialApiCall();
  };

  const resetFilters = () => {
    setFilters({
      name__icontains: "",
      is_active: "",
    });
    setoffset(0);
    initialApiCall();
  };

  const totalDegrees = degrees?.count || 0;
  const activeDegrees = degrees?.results?.filter((degree) => degree.is_active)?.length || 0;
  const inactiveDegrees = totalDegrees - activeDegrees;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <span>
          {record.is_active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: 'right',
      render: (text, record) => (
        <div className="flex gap-3">
          <Tooltip title="Edit">
            <div
              onClick={() => edit(record)}
              className="shadow py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              placement="topRight"
              title="Delete item"
              description="Are you sure to delete this item?"
              onConfirm={() => confirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <div className="shadow py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                <FontAwesomeIcon icon={faTrashCan} />
              </div>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h3>All Degrees</h3>
        <div className="flex items-center gap-5">
          <div>
            <Input
              placeholder="Search degree"
              value={filters.name__icontains}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  name__icontains: e.target.value,
                }))
              }
            />
          </div>
          <Button
            type="primary"
            onClick={() => {
              setMode("create");
              setisModalOpen(true);
            }}
          >
            Add degrees
          </Button>
        </div>

        <PostModal
          mode={mode}
          type={type}
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setisModalOpen(false);
            setformData({});
          }}
          fetchDataAfterFormSubmit={initialApiCall}
          formData={formData}
        >
          <Form.Item
            label="Name"
            name="name"
            className="required font-semibold"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
        </PostModal>
      </div>

      {/* Statistics Section */}
      <Row gutter={16} className="mb-5">
        <Col span={8}>
          <Card>
            <Statistic title="Total Degrees" value={totalDegrees} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Active Degrees" value={activeDegrees} valueStyle={{ color: "#3f8600" }} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Inactive Degrees" value={inactiveDegrees} valueStyle={{ color: "#cf1322" }} />
          </Card>
        </Col>
      </Row>

      {/* Filter Section */}
      <div className="mb-5 border shadow-lg py-7 px-5 pb-0 bg-white rounded-lg">
        <Form>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="name__icontains">
                <Input
                  placeholder="Filter by Name"
                  value={filters.name__icontains}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      name__icontains: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="is_active">
                <Select
                  placeholder="Filter by Status"
                  value={filters.is_active}
                  onChange={(value) =>
                    setFilters((prev) => ({
                      ...prev,
                      is_active: value,
                    }))
                  }
                  allowClear
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Button type="primary" onClick={applyFilters} block>
                Filter
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Button type="default" onClick={resetFilters} block>
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={degrees.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="drop-shadow-md"
      />

      <BasicPagination
        total={degrees.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />
    </div>
  );
}
