import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Table, Tag, Tooltip, Input, Select, Row, Col, Card, Statistic, Form } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BasicPagination from "../../../components/BasicPagination";
import adminStore from "../../../stores/adminStore";
import { Constant } from "../../../utils/constant";
import { toastSuccess } from "../../../utils/helper";
import InstituteForm from "./InstituteForm";

const { Option } = Select;

const type = Constant.institutes;

export default function InstitutesList() {
  const { institutes, fetchInstitute, deleteModalStore, countries } = adminStore();
  const [formData, setformData] = useState(null);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [loading, setloading] = useState(true);
  const [limit, setlimit] = useState(24);
  const [offset, setoffset] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    country: "",
    is_active: "",
  });
  
  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  useEffect(() => {
    initialApiCall();
  }, [limit, offset]);

  const initialApiCall = () => {
    setloading(true);
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);
    if (filters.search) params.append("search", filters.search);
    if (filters.country) params.append("country", filters.country);
    if (filters.is_active !== "") params.append("is_active", filters.is_active);
    params.append("order", "-created_at");

    fetchInstitute(params.toString()).finally(() => {
      setloading(false);
    });
  };

  const confirm = (id) => {
    deleteModalStore(id, type).then(() => {
      toastSuccess("Deleted successfully!");
      initialApiCall();
    });
  };

  const edit = (formData) => {
    setMode("edit");
    setformData(formData);
    setisModalOpen(true);
  };

  const applyFilters = () => {
    setoffset(0); // Reset to the first page when applying filters
    initialApiCall();
  };

  const resetFilters = () => {
    setFilters({
      search: "",
      country: "",
      is_active: "",
    });
    setoffset(0); // Reset to the first page
    initialApiCall();
  };

  const totalInstitutes = institutes?.count || 0;
  const activeInstitutes = institutes?.results?.filter((institute) => institute.is_active)?.length || 0;
  const inactiveInstitutes = totalInstitutes - activeInstitutes;

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 250,
    },
    {
      title: "Country",
      dataIndex: ["country_detail", "name"],
      key: "country",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 250,
    },
    {
      title: "Institute Type",
      dataIndex: "institute_type",
      key: "institute_type",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (text, record) => <img src={record.logo} alt="" width={60} />,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <span>
          {record.is_active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (text, record) => (
        <div className="flex gap-2">
          <Tooltip title="Edit">
            <div
              onClick={() => edit(record)}
              className="shadow py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              placement="topRight"
              title="Delete item"
              description="Are you sure to delete this item?"
              onConfirm={() => confirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <div className="shadow py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                <FontAwesomeIcon icon={faTrashCan} />
              </div>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h3>Institutes List</h3>
        <div className="flex items-center gap-5">
          <Button
            type="primary"
            onClick={() => {
              setMode("create");
              setisModalOpen(true);
            }}
          >
            Add Institute
          </Button>
        </div>

        <InstituteForm
          width={1200}
          mode={mode}
          type={type}
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setisModalOpen(false);
            setformData({});
          }}
          fetchDataAfterFormSubmit={initialApiCall}
          formData={formData}
        />
      </div>

      {/* Statistics Section */}
      <Row gutter={16} className="mb-5">
        <Col span={8}>
          <Card>
            <Statistic title="Total Institutes" value={totalInstitutes} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Active Institutes" value={activeInstitutes} valueStyle={{ color: "#3f8600" }} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Inactive Institutes" value={inactiveInstitutes} valueStyle={{ color: "#cf1322" }} />
          </Card>
        </Col>
      </Row>

      {/* Filter Section */}
      <div className="mb-5 border shadow-lg py-7 px-5 bg-white rounded-lg">
        <Form>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="search">
                <Input
                  placeholder="Search by Institute Name"
                  value={filters.search}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      search: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="country">
                <Select
                  placeholder="Filter by Country"
                  value={filters.country}
                  onChange={(value) =>
                    setFilters((prev) => ({
                      ...prev,
                      country: value,
                    }))
                  }
                  allowClear
                >
                  {countries?.length > 0 && countries.map((country) => (
                    <Option key={country.id} value={country.id}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="is_active">
                <Select
                  placeholder="Filter by Status"
                  value={filters.is_active}
                  onChange={(value) =>
                    setFilters((prev) => ({
                      ...prev,
                      is_active: value,
                    }))
                  }
                  allowClear
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Button type="primary" onClick={applyFilters} block>
                Filter
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Button type="default" onClick={resetFilters} block>
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={institutes.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="drop-shadow-md"
      />

      <BasicPagination
        total={institutes.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />
    </div>
  );
}
