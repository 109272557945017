export const Constant = {
  student: "student",
  admin: "admin",
  counsellor: "counsellor",
  agent: "agent",
  super_admin: "super_admin",
  application_status: "Application status",
  // video_course_lessons: "Video course lessons",

  degrees: "degrees",
  countries: "countries",
  courses: "courses",
  coursesCategories: "courses-categories",
  institutes: "institutes",
  documents: "documents",

  // success and error messages
  courseApplied_successfully: "Applied successfully. We will contact you soon!",
  courseApplied_error: "Error while applying!",

  // user
  userRegister_successfully: "User registered successfully!",
};
