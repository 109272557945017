import {
  faSignInAlt,
  faRightFromBracket,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import { webRoutes } from "../../routes/web";

export default function WebsiteNavbar() {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);

  // Add scroll listener to toggle background color
  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate(webRoutes.home);
  };

  return (
    <div
      className={`top-navbar fixed w-full z-100 p-5 transition-all duration-300 ${
        scrolling ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="flex justify-between items-center">
        <Link to={webRoutes.home}>
          <h3 className="text-xl font-bold">EDUDOKO</h3>
        </Link>
        <div className="flex items-center">
          {currentUser ? (
            <>
              <Link
                to={currentUser.role === 'student' ? webRoutes.studentProfile : webRoutes.dashboard}
                className={`text-sm font-medium mr-5 flex items-center transition-all duration-300 ${
                  scrolling
                    ? "hover:text-blue-600"
                    : "bg-white/70 text-black hover:bg-white/80 px-3 py-2 rounded-lg"
                }`}
              >
                <FontAwesomeIcon icon={faTachometerAlt} className="mr-2 text-lg" />
                <span>Dashboard</span>
              </Link>
              <div
                className={`text-sm font-medium flex items-center cursor-pointer transition-all duration-300 ${
                  scrolling
                    ? "hover:text-red-600"
                    : "bg-white/70 text-black hover:bg-white/80 px-3 py-2 rounded-lg"
                }`}
                onClick={handleLogout}
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="mr-2 text-lg"
                />
                <span>Sign Out</span>
              </div>
            </>
          ) : (
            <Link
              to={webRoutes.login}
              className={`text-sm font-medium flex items-center transition-all duration-300 ${
                scrolling
                  ? "hover:text-green-600"
                  : "bg-white/70 text-black hover:bg-white/80 px-3 py-2 rounded-lg"
              }`}
            >
              <FontAwesomeIcon icon={faSignInAlt} className="mr-2 text-lg" />
              <span>Sign In</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
