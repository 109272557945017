import { Drawer } from "antd";
import React from "react";
import RegisterComponent from "../../../components/auth/RegisterComponent";

export default function CreateStudentDrawer(props) {
  const { isModalOpen, handleCancel, fetchUserIfNecessary, mode } = props;

  return (
    <Drawer
      title={`Add ${mode}`}
      placement="right"
      onClose={handleCancel}
      open={isModalOpen}
      width={720}
    >
      <RegisterComponent
        onCancel={handleCancel}
        fetchUserIfNecessary={fetchUserIfNecessary}
        mode={mode}
        isSignUp={false}
      />
    </Drawer>
  );
}
