import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  Card,
  Statistic,
  Select,
  Form,
  Badge,
} from "antd";
import {
  faEye,
  faPenToSquare,
  faTrashCan,
  faUserTag,
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import EditRegisterComponent from "../../../components/auth/EditRegisterComponent";
import Permission from "../../../components/auth/Permission";
import BasicPagination from "../../../components/BasicPagination";
import { webRoutes } from "../../../routes/web";
import authStore from "../../../stores/authStore";
import studentStore from "../../../stores/studentStore";
import { Constant } from "../../../utils/constant";
import { GetUserRole, toastSuccess } from "../../../utils/helper";
import AssignCounsellorModal from "./AssignCounsellorModal";
import CreateStudentModal from "./CreateStudentModal";
import moment from "moment";
import loaderStore from "../../../stores/loaderStore";
import counsellorStore from "../../../stores/counsellorStore";
import agentStore from "../../../stores/agentStore";

const { Option } = Select;

export default function StudentList(props) {
  const { userId, mode } = props;
  const { studentLists, getStudentList } = studentStore();
  const { counsellorList, getAllCounsellor } = counsellorStore();
  const { agentList, getAllAgent } = agentStore();
  const { deleteUser, updateCounsellorFromAuth } = authStore();
  const { showLoader, hideLoader } = loaderStore();

  const navigate = useNavigate();

  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(50);
  const [loading, setLoading] = useState(true);
  const [selectedStudentId, setselectedStudentId] = useState(null);
  const [filterForm] = Form.useForm();
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [isAssignCounsellorDrawerOpen, setisAssignCounsellorDrawerOpen] =
    useState(false);
  const [courses, setCourses] = useState([]);

  const [formData, setformData] = useState({});
  const [isEditDrawerOpen, setisEditDrawerOpen] = useState(false);
  const handleEditDrawerCancel = () => setisEditDrawerOpen(false);

  // Updated filters state
  const [filters, setFilters] = useState({
    course: "",
    agent: "",
    counsellor: "",
    email__icontains: "",
    first_name__icontains: "",
    last_name__icontains: "",
    passport_number__icontains: "",
    phone__icontains: "",
  });

  const params = new URLSearchParams();
  params.append("offset", offset);
  params.append("limit", limit);
  params.append("order", "-updated_at");
  params.append("role", "student");
  if (mode && userId) params.append(mode, userId);

  useEffect(() => {
    fetchStudentListFromAPI();
    if (GetUserRole() === Constant.admin || GetUserRole() === Constant.super_admin) {
      fetchAgents();
      fetchCounsellors();
    }
  }, [limit, offset]);

  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  const fetchAgents = async () => {
    const params = new URLSearchParams();
    params.append("offset", 0);
    params.append("limit", 200);

    getAllAgent(params.toString()).finally(() => { });
  };

  const fetchCounsellors = async () => {
    const params = new URLSearchParams();
    params.append("offset", 0);
    params.append("limit", 200);

    getAllCounsellor(params.toString()).finally(() => { });
  };

  const fetchStudentListFromAPI = () => {
    Object.keys(filters).map((key) => {
      if (filters[key]) {
        params.set(key, filters[key]);
      }
    });

    getStudentList(params.toString()).finally(() => {
      setLoading(false);
    });
  };

  const removeUser = async (id) => {
    deleteUser(id).then(() => {
      toastSuccess("User has been deleted successfully!");
      fetchStudentListFromAPI();
    });
  };

  const confirm = (e) => {
    removeUser(e);
  };

  const edit = (formData) => {
    setformData(formData);
    setisEditDrawerOpen(true);
  };

  const cancel = (e) => { };

  const removeCounsellor = (id) => {
    showLoader();
    updateCounsellorFromAuth(id, { counsellor: null })
      .then((response) => {
        toastSuccess("Counsellor removed successfully!");
        fetchStudentListFromAPI();
      })
      .catch((onRejected) => {
        console.log(onRejected);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleFilterChange = (value, type) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, [type]: value || "" };
      console.log("Updated Filters:", updatedFilters); // Debugging line
      return updatedFilters;
    });
    setoffset(0);
  };

  const resetFilters = () => {
    setFilters({
      course: "",
      agent: "",
      counsellor: "",
      email__icontains: "",
      first_name__icontains: "",
      last_name__icontains: "",
      passport_number__icontains: "",
      phone__icontains: "",
    });
    filterForm.resetFields();
    setoffset(0);
    fetchStudentListFromAPI();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Progress Status",
      dataIndex: "student_applications",
      key: "student_applications",
      render: (text, record) => (
        <span className="inline-grid">
          {record?.student_applications?.map((progress, index) => (
            <div className="mb-2 last:mb-0" key={index}>
              <Tag color="blue">
                {index + 1}. {progress.progress_track_json[0].title}
              </Tag>
            </div>
          ))}
        </span>
      ),
    },
    {
      title: "Counsellor",
      dataIndex: "counsellor_detail",
      key: "counsellor",
      render: (text, record) => (
        <span>
          {record.counsellor_detail?.first_name}{" "}
          {record.counsellor_detail?.last_name}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <span>
          {record.is_active ? (
            <Badge
              status=""
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: 'green',
              }}
            />
          ) : (
            <Badge
              status=""
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: 'red',
              }}
            />
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (text, record) => (
        <span>{moment(record.date_joined).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: ["id", "first_name", "last_name"],
      key: "id",
      fixed: "right",
      render: (text, record) => (
        <div className="flex gap-2">
          <Tooltip title="View details">
            <div
              className="shadow py-1 px-2 text-slate-500 bg-blue-100 rounded-md cursor-pointer hover:bg-blue-200 hover:text-slate-600 hover:shadow"
              onClick={() =>
                navigate(webRoutes.studentProfile + "?student=" + record.id)
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Tooltip>

          <Tooltip title="Edit User">
            <div
              className="shadow py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
              onClick={() => edit(record)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Tooltip title="Assign counsellor">
              <div
                className="shadow py-1 px-2 text-slate-500 bg-green-100 rounded-md cursor-pointer hover:bg-green-200 hover:text-slate-600 hover:shadow"
                onClick={() => {
                  setisAssignCounsellorDrawerOpen(true);
                  setselectedStudentId({
                    id: record.id,
                    name: record.first_name + " " + record.last_name,
                  });
                }}
              >
                <FontAwesomeIcon icon={faUserTag} />
              </div>
            </Tooltip>
            {record.counsellor && (
              <Tooltip title="Remove counsellor">
                <div
                  className="shadow py-1 px-2 text-red-700 bg-red-300 rounded-md cursor-pointer hover:bg-red-400 hover:text-red-700 hover:shadow"
                  onClick={() => {
                    removeCounsellor(record.id);
                  }}
                >
                  <FontAwesomeIcon icon={faUserXmark} />
                </div>
              </Tooltip>
            )}
          </Permission>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Tooltip title="Delete user">
              <Popconfirm
                placement="topRight"
                title="Delete user"
                description="Are you sure to delete this user?"
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <div className="shadow py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
    },
  ];

  const rowClassName = (record) => {
    if (record.student_applications && record.student_applications.length) {
      const isExpired = record.student_applications?.some((application) => {
        const updatedAt = moment(application.updated_at);
        const today = moment();
        return today.diff(updatedAt, "days") > 7;
      });

      if (isExpired) {
        return "row-expired";
      }
    }
    return "";
  };

  return (
    <div>
      <div className="md:flex justify-between items-center mb-5">
        <h3 className="mb-4 md:mb-0">All Students</h3>

        <div className="flex items-center gap-5">
          {window.location.pathname === webRoutes.studentLists && (
            <Button type="primary" onClick={() => setisDrawerOpen(true)}>
              Add student
            </Button>
          )}
        </div>
      </div>

      <Row gutter={16} className="mb-5">
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Total Students"
              value={studentLists?.count}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Active Students"
              value={studentLists?.results?.filter(
                (student) => student.is_active
              ).length}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Pending Applications"
              value={studentLists?.results?.filter((student) =>
                student.student_applications?.some(
                  (app) => app.status === "pending"
                )
              ).length}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
      </Row>

      <div className="mb-5 border shadow-lg py-7 px-5 pb-0 bg-white rounded-lg">
        <Form
          form={filterForm}
          onFinish={fetchStudentListFromAPI}
        >
          <Row gutter={[16, 16]}>
            {GetUserRole() === Constant.admin || GetUserRole() === Constant.super_admin &&
              <>
                {!mode &&
                  <>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Form.Item name="agent">
                        <Select
                          showSearch
                          placeholder="Filter by agent"
                          style={{ width: "100%" }}
                          onChange={(value) => handleFilterChange(value, "agent")}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {agentList.map(agent => (
                            <Option key={agent.id} value={agent.id}>
                              {agent.first_name} {agent.last_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Form.Item name="counsellor">
                        <Select
                          showSearch
                          placeholder="Filter by counsellor"
                          style={{ width: "100%" }}
                          onChange={(value) => handleFilterChange(value, "counsellor")}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {counsellorList.map(counsellor => (
                            <Option key={counsellor.id} value={counsellor.id}>
                              {counsellor.first_name} {counsellor.last_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                }
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item name="status">
                    <Select
                      placeholder="Filter by status"
                      style={{ width: "100%" }}
                      onChange={(value) => handleFilterChange(value, "is_active")}
                      allowClear
                    >
                      <Option key="active" value={'true'}>
                        Active
                      </Option>
                      <Option key="inactive" value={'false'}>
                        Inactive
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            }
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="email__icontains">
                <Input
                  placeholder="Filter by Email"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "email__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="first_name__icontains">
                <Input
                  placeholder="Filter by First Name"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "first_name__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="last_name__icontains">
                <Input
                  placeholder="Filter by Last Name"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "last_name__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="passport_number__icontains">
                <Input
                  placeholder="Filter by Passport No."
                  onChange={(e) =>
                    handleFilterChange(
                      e.target.value,
                      "passport_number__icontains"
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="phone__icontains">
                <Input
                  placeholder="Filter by Phone"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "phone__icontains")
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="default" onClick={resetFilters} className="py-4 h-auto" block>
                  Reset Filters
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="py-4 h-auto" block>
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={studentLists.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="shadow-lg"
        rowClassName={rowClassName}
      />

      <BasicPagination
        total={studentLists.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />

      {/* Assign Counsellor Drawer */}
      <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
        <AssignCounsellorModal
          isModalOpen={isAssignCounsellorDrawerOpen}
          handleCancel={() => setisAssignCounsellorDrawerOpen(false)}
          studentData={selectedStudentId}
          fetchUserIfNecessary={fetchStudentListFromAPI}
        />
      </Permission>

      {/* Create Student Drawer */}
      <CreateStudentModal
        isModalOpen={isDrawerOpen}
        handleCancel={() => setisDrawerOpen(false)}
        fetchUserIfNecessary={fetchStudentListFromAPI}
        mode="student"
      />

      {/* Edit Student Drawer */}
      <Drawer
        title="Edit Student"
        placement="right"
        width={720}
        onClose={handleEditDrawerCancel}
        open={isEditDrawerOpen}
      >
        {Object.keys(formData).length > 0 && (
          <EditRegisterComponent
            onCancel={handleEditDrawerCancel}
            mode="student"
            formData={formData}
            fetchUserIfNecessary={fetchStudentListFromAPI}
          />
        )}
      </Drawer>
    </div>
  );
}
