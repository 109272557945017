import { Button, DatePicker, Form, Input } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { webRoutes } from "../../routes/web";
import authStore from "../../stores/authStore";
import { Constant } from "../../utils/constant";
import { GetUserRole, getUserToken, toastSuccess } from "../../utils/helper";

export default function RegisterComponent(props) {
  const { onCancel, fetchUserIfNecessary, mode, isSignUp } = props;
  const { signup, registerStudentFromAuth } = authStore();

  const [loading, setLoading] = useState(false);
  const [DOB, setDOB] = useState(null);
  const navigate = useNavigate();

  const onChange = (date, dateString) => {
    setDOB(dateString);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formValue = { ...values, role: mode, dob: moment(DOB).format("YYYY-MM-DD") };

    const userRole = GetUserRole();
    if (userRole) {
      if (userRole === Constant.admin || userRole === Constant.super_admin) {
        formValue.role = mode;
      }
      registerStudentFromAuth(formValue)
        .then(() => {
          toastSuccess(Constant.userRegister_successfully);
          onCancel();
          fetchUserIfNecessary();
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    } else {
      signup(formValue)
        .then(() => {
          toastSuccess(Constant.userRegister_successfully);
          navigate(webRoutes.userProfile);
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const renderTitle = () => {
    return isSignUp ? <><h2 className="mb-5">Sign Up</h2> <hr className="mb-6" /> </> : <></>;
  };

  const buttonText = isSignUp ? 'Sign up' : `Add ${mode}`;

  const renderSignInLink = () => {
    if (!getUserToken()) {
      return (
        <p className="font-semibold text-sm text-center mt-6">
          Already have an account?{" "}
          <Link to={webRoutes.login} className="cursor-pointer">
            Sign in here.
          </Link>
        </p>
      );
    }
    return null;
  };

  return (
    <Form
      layout={"vertical"}
      className={isSignUp ? "border rounded-lg shadow-lg" : ""}
      style={{
        maxWidth: 720,
        marginLeft: "auto",
        marginRight: "auto",
        padding: isSignUp ? '2rem' : '0'
      }}
      requiredMark={false}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError
    >
      {renderTitle()}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-3">
        <Form.Item
          label="First Name"
          className="required font-semibold"
          name={"first_name"}
          rules={[{ required: true, message: "Please input your First Name!" }]}
        >
          <Input className="mb-2" size="large" placeholder="Enter your first name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          className="required font-semibold"
          name={"last_name"}
          rules={[{ required: true, message: "Please input your Last Name!" }]}
        >
          <Input className="mb-2" size="large" placeholder="Enter your last name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          className="required font-semibold"
          rules={[
            { type: "email", message: "The input is not valid E-mail!" },
            { required: true, message: "Please input your E-mail!" },
          ]}
        >
          <Input placeholder="Enter your email address" size="large" />
        </Form.Item>

        <Form.Item
          label="Phone No."
          className="required font-semibold"
          name={"phone"}
          rules={[
            { required: true, message: "Please input your Phone Number!" },
            { pattern: /^\d{10}$/, message: "Phone number must be exactly 10 digits!" },
          ]}
        >
          <Input className="mb-2 w-full" size="large" placeholder="Enter your mobile number" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="required font-semibold"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="mb-2" size="large" placeholder="Enter your password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="password2"
          className="required font-semibold"
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password className="mb-2" size="large" placeholder="Enter your password" />
        </Form.Item>

        <Form.Item
          label="DOB"
          className="required font-semibold"
          name={"dob"}
          rules={[{ required: true, message: "Please input your Date of Birth!" }]}
        >
          <DatePicker onChange={onChange} size="large" className="w-full" />
        </Form.Item>

        <Form.Item label="Address" className="font-semibold" name={"address"}>
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item label="Passport Number" className="font-semibold" name={"passport_number"}>
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item label="Consultancy" className="font-semibold" name={"consultancy"}>
          <Input className="mb-2" size="large" />
        </Form.Item>
      </div>

      <Form.Item className="mb-0">
        <Button
          type="primary"
          size="large"
          className="w-full"
          htmlType="submit"
          loading={loading}
        >
          {buttonText}
        </Button>
      </Form.Item>

      {renderSignInLink()}
    </Form>
  );
}
