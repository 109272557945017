import {
  faEye,
  faPenToSquare,
  faTrashCan,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Drawer, Popconfirm, Table, Tag, Tooltip, Row, Col, Card, Statistic, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "../../../routes/web";
import studentStore from "../../../stores/studentStore";
import BasicPagination from "../../../components/BasicPagination";
import EditRegisterComponent from "../../../components/auth/EditRegisterComponent";
import Permission from "../../../components/auth/Permission";
import authStore from "../../../stores/authStore";
import Debounce from "../../../utils/Debounce";
import { Constant } from "../../../utils/constant";
import { GetUserRole, toastSuccess } from "../../../utils/helper";
import CreateStudentModal from "../student/CreateStudentModal";
import loaderStore from "../../../stores/loaderStore";
import moment from "moment";

const { Option } = Select;

export default function CounsellorLists() {
  const { studentLists, getStudentList } = studentStore();
  const { showLoader, hideLoader } = loaderStore();
  const { deleteUser } = authStore();

  const navigate = useNavigate();

  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [formData, setformData] = useState({});
  const [isEditDrawerOpen, setisEditDrawerOpen] = useState(false); // Drawer state
  const [isModalOpen, setisModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = Debounce(search, 500);

  // Filter form and filter state for counsellor
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState({
    email__icontains: "",
    first_name__icontains: "",
    last_name__icontains: "",
    phone__icontains: "",
    is_active: "",
  });

  const mode = Constant.counsellor;

  useEffect(() => {
    getCounsellorList();
  }, [limit, offset, debouncedSearchTerm, filters]);

  const getCounsellorList = () => {
    const params = new URLSearchParams();
    params.append("offset", offset);
    params.append("limit", limit);
    params.append("search", search);
    params.append("order", "-updated_at");

    Object.keys(filters).map((key) => {
      if (filters[key]) {
        params.set(key, filters[key]);
      }
    });

    if (GetUserRole() === Constant.admin || GetUserRole() === Constant.super_admin) {
      params.append("role", "counsellor");
    }
    getStudentList(params.toString()).finally(() => {
      setLoading(false);
    });
  };

  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  const confirm = (id) => {
    showLoader();
    deleteUser(id)
      .then(() => {
        toastSuccess("User has been deleted successfully!");
        getCounsellorList();
      })
      .catch(() => {})
      .finally(() => hideLoader());
  };

  const edit = (formData) => {
    setformData(formData);
    setisEditDrawerOpen(true); // Open drawer
  };

  const cancel = (e) => {};

  const handleFilterChange = (value, type) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, [type]: value || "" };
      return updatedFilters;
    });
    setoffset(0);
  };

  const resetFilters = () => {
    setFilters({
      email__icontains: "",
      first_name__icontains: "",
      last_name__icontains: "",
      phone__icontains: "",
      is_active: "",
    });
    filterForm.resetFields();
    setoffset(0);
    getCounsellorList();
  };

  const handleEditDrawerClose = () => {
    setisEditDrawerOpen(false); // Close drawer
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["first_name", "last_name"],
      key: "name",
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Consultancy Name",
      dataIndex: "consultancy",
      key: "consultancy",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <span>
          {record.is_active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (text, record) => (
        <span>
          {moment(record.date_joined).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (text, record) => (
        <div className="flex gap-3">
          <Tooltip title="View details">
            <div
              className="py-1 px-2 text-slate-500 bg-blue-100 rounded-md cursor-pointer hover:bg-blue-200 hover:text-slate-600 hover:shadow"
              onClick={() =>
                navigate(webRoutes.counsellorLists + "/" + record.id)
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Tooltip>

          <Tooltip title="Edit User">
            <div
              className="py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
              onClick={() => edit(record)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Tooltip title="Delete user">
              <Popconfirm
                placement="topRight"
                title="Delete user"
                description="Are you sure to delete this user?"
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <div className="py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h3>All Counsellors</h3>
        <div className="flex items-center gap-5">
          <Button type="primary" onClick={() => setisModalOpen(true)}>
            Add counsellor
          </Button>
        </div>

        <CreateStudentModal
          isModalOpen={isModalOpen}
          handleCancel={() => setisModalOpen(false)}
          fetchUserIfNecessary={() => getCounsellorList()}
          mode="counsellor"
        />

        <Drawer
          title={mode + " Update"}
          placement="right"
          width={720}
          onClose={handleEditDrawerClose} // Close drawer
          open={isEditDrawerOpen} // Use drawer's state
        >
          {Object.keys(formData).length > 0 && (
            <EditRegisterComponent
              onCancel={handleEditDrawerClose} // Close drawer
              mode={mode}
              formData={formData}
              fetchUserIfNecessary={() => getCounsellorList()}
            />
          )}
        </Drawer>
      </div>

      <Row gutter={16} className="mb-5">
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Total Counsellors"
              value={studentLists?.count}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Active Counsellors"
              value={studentLists?.results?.filter(
                (student) => student.is_active
              ).length}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className="shadow-lg">
            <Statistic
              title="Inactive Counsellors"
              value={studentLists?.results?.filter(
                (student) => !student.is_active
              ).length}
              prefix={<FontAwesomeIcon icon={faUserTag} />}
            />
          </Card>
        </Col>
      </Row>

      <div className="mb-5 border shadow-lg py-7 px-5 pb-0 bg-white rounded-lg">
        <Form form={filterForm}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="email__icontains">
                <Input
                  placeholder="Filter by Email"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "email__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="first_name__icontains">
                <Input
                  placeholder="Filter by First Name"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "first_name__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="last_name__icontains">
                <Input
                  placeholder="Filter by Last Name"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "last_name__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="phone__icontains">
                <Input
                  placeholder="Filter by Phone"
                  onChange={(e) =>
                    handleFilterChange(e.target.value, "phone__icontains")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item name="status">
                <Select
                  placeholder="Filter by Status"
                  onChange={(value) => handleFilterChange(value, "is_active")}
                  allowClear
                >
                  <Option key="active" value={"true"}>
                    Active
                  </Option>
                  <Option key="inactive" value={"false"}>
                    Inactive
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="default" onClick={resetFilters} block>
                  Reset Filters
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="primary" onClick={getCounsellorList} block>
                  Apply Filters
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={studentLists.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="drop-shadow-md"
      />

      <BasicPagination
        total={studentLists.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />
    </div>
  );
}
