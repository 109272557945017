import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import studentStore from "../../../stores/studentStore";
import counsellorStore from "../../../stores/counsellorStore";

export default function AssignCounsellorModal(props) {
  const { isModalOpen, handleCancel, studentData, fetchUserIfNecessary } = props;

  const { counsellorList, getAllCounsellor } = counsellorStore();
  const { studentLists, getStudentList, UPDATE_student } = studentStore();
  const [selectedCounsellor, setSelectedCounsellor] = useState(null);

  const handleChange = (value) => {
    setSelectedCounsellor(value);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("offset", 0);
    params.append("limit", 200);

    getAllCounsellor(params.toString()).finally(() => {});
  }, []);

  const handleSubmit = () => {
    const data = {
      id: studentData.id,
      data: {
        counsellor: selectedCounsellor,
      },
    };

    UPDATE_student(data).then((onFulfilled) => {
      if (onFulfilled) {
        handleCancel();
        fetchUserIfNecessary();
      }
    });
  };

  const getCounsellorList = () => {
    return counsellorList.map((item) => ({
      label: item.first_name + " " + item.last_name,
      value: item.id,
    }));
  };

  return (
    <Modal
      title={"Assign counsellor to " + studentData?.name}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={false}
    >
      <Select
        className="w-full"
        onChange={handleChange}
        options={getCounsellorList()}
      />

      <Button
        type="primary"
        className="w-full mt-5"
        onClick={() => handleSubmit()}
      >
        Assign
      </Button>
    </Modal>
  );
}
