// store.js
import { create } from "zustand";
import axiosInstance from "../routes/axiosInstance";
import { apiList } from "../routes/api";
import { AuthRefreshToken, GetUserRole, getUserToken } from "../utils/helper";
import { Constant } from "../utils/constant";

let url;
const studentStore = create((set) => ({
  documents: [],
  applications: [],
  academicDocumentList: [],
  studentLists: [],
  videoCourseEnrollmentsList: [],

  getAllDocument: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentDocumentList;
        break;

      case Constant.agent:
        url = apiList.agentStudentAcademicDocuments;
        break;

      case Constant.counsellor:
        url = apiList.counsellorAcademicDocuments;
        break;

      default:
        url = apiList.adminStudentAcademicDocuments;
        break;
    }

    const response = await axiosInstance.get(
      params ? url + "?user=" + params : url,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ documents: response.data.results });
    }
  },

  createApplications: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentApplicationList;
        break;

      case Constant.agent:
        url = apiList.agentStudentApplicationCreate;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentApplications;
        break;

      default:
        url = apiList.adminStudentApplicationList;
        break;
    }

    const response = await axiosInstance.post(url, params, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    console.log(response);
    if (response.status === 200) {
      return response.status;
    } else if (response.status === 201) {
      return response.status;
    } else {
      return false;
    }
  },

  updateApplications: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentApplicationList;
        break;

      case Constant.agent:
        url = apiList.agentStudentApplicationCreate;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentApplications;
        break;

      default:
        url = apiList.adminStudentApplicationList;
        break;
    }

    const response = await axiosInstance.post(
      url + params.id + "/update_progress_track_json/",
      params.value,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );

    if (response.status === 200) {
      return response.status;
    } else if (response.status === 201) {
      return response.status;
    } else {
      return false;
    }
  },

  deleteApplication: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentApplicationList;
        break;

      case Constant.agent:
        url = apiList.agentStudentApplicationCreate;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentApplications;
        break;

      default:
        url = apiList.adminStudentApplicationList;
        break;
    }

    const response = await axiosInstance.delete(url + params, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });

    if (response.status === 200) {
      return response.status;
    } else if (response.status === 201) {
      return response.status;
    } else {
      return false;
    }
  },

  getAllApplications: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentApplicationList;
        break;

      case Constant.agent:
        url = apiList.agentStudentApplicationCreate;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentApplications;
        break;

      default:
        url = apiList.adminStudentApplicationList;
        break;
    }

    const response = await axiosInstance.get(
      params ? url + "?student=" + params : url,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ applications: response.data.results });
    }
  },

  getAcademicDocumentNames: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.academicDocumentNames;
        break;

      default:
        url = apiList.academicDocumentNames;
        break;
    }

    const response = await axiosInstance.get(params ? url + params : url, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      set({ academicDocumentList: response.data.results });
    }
  },

  studentUploadDocument: async (data) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentAcademicDocuments;
        break;

      case Constant.agent:
        url = apiList.agentStudentAcademicDocuments;
        break;

      case Constant.counsellor:
        url = apiList.counsellorAcademicDocuments;
        break;

      default:
        url = apiList.adminStudentAcademicDocuments;
        break;
    }

    const response = await axiosInstance.post(url, data, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
        "Content-type": "multipart/form-data",
      },
    });
    if (response.status === 201) {
      return response.data;
    }
  },

  getStudentList: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.agent:
        url = apiList.agentStudentList;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentList;
        break;

      default:
        url = apiList.adminAllUsers;
        break;
    }

    const response = await axiosInstance.get(
      params ? url + "?" + params : url,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ studentLists: response.data });
      return { success: true };
    } else {
      throw new Error("API Error");
    }
  },

  removeUploadedDocument: async (id) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentDocumentList;
        break;

      case Constant.counsellor:
        url = apiList.counsellorAcademicDocuments;
        break;

      case Constant.agent:
        url = apiList.agentStudentAcademicDocuments;
        break;

      default:
        url = apiList.adminStudentAcademicDocuments;
        break;
    }
    const response = await axiosInstance.delete(url + id, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    console.log(response);
    if (response.status === 200) {
      set({ videoCourseEnrollmentsList: response.data.results });
    }
  },

  UPDATE_student: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentApplicationList;
        break;

      case Constant.agent:
        url = apiList.agentStudentApplicationCreate;
        break;

      default:
        url = apiList.adminAllUsers;
        break;
    }

    const response = await axiosInstance.patch(url + params.id, params.data, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });

    if (response.status === 200) {
      return response.status;
    } else if (response.status === 201) {
      return response.status;
    } else {
      return false;
    }
  },
}));

export default studentStore;
